body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w20 {
    width: 20%;
}

.w60 {
    width: 60%;
}

.workPeriodModalWrapper .bx--modal-container {
  width: 40%;
}

.inline {
    display: inline-block;
}

.empty {
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(61, 112, 178);
    width: 20%;
}

.newWorkPeriod {
    position: relative;
    left: 98%;
    margin-bottom: 10px;
    font-size: 22px;
}

.largeModal .bx--modal-container {
    width: 80%;
    max-width: 80%;
}

input[type=text], input[type=number], input[type=textare] {
    box-shadow: none;
}

.newWorkPeriodForm .bx--form-item,
.newProjectForm .bx--form-item,
.newTaskForm .bx--form-item,
.startSessionForm .bx--form-item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .bx--form-item:first-child,
.form-inline .bx--form-item:last-child {
    width: 40%;
}

.form-inline .bx--form-item:nth-of-type(2) {
    width: 20%;
    max-width: 20%;
}

.form-inline .bx--number {
    margin-left: auto;
    margin-right: auto;
}

/* Style the input fields */
.form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.multilineSwitch {
    margin-bottom: 10px;
}
.multilineSwitch:focus {
    border: none;
}

.removeLineButton {
    margin-left: 5px;
}

.addLineButton {
    float: right;
    margin-top: 10px;
}

.sessionButton {
    float: right;
}

.startSessionModal .bx--modal-container {
    height: 60%;
    width: 60%;
    max-width: 60%;
}

.startSessionModal .bx--modal-content {
    height: 100%;
    overflow-y: hidden;
}

.currentSessionText {
    margin-right: 10px;
    font-size: small;
}

.currentSessionText span {
    margin-left: 5px;
    font-size: small;
    color: #5e5e5e;
    font-weight: bold;
}

.currentSessionText span:first-child::after {
    content: " | ";
    margin-left: 5px;
}

.timeInput {
    display: flex;
    flex-direction: row;
    width: 40%;
}
.timeInput .timeInputField {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.timeInputField label {
    font-weight: 100;
}

.loginForm {
    width: 40%;
    margin-left: 30%;
    margin-top: 10%;
}
.loginForm input {
    margin-bottom: 40px;
}

.pull-right {
    position: absolute;
    right: 5%;
}

.smaller {
    font-size: smaller;
}

.timeusage {
    margin-bottom: 10px;
    color: #3d70b2;
}

.bx--accordion__item:first-child {
    /*border-bottom: none !important;*/
    background-color: white;
}
.bx--accordion__content {
    padding-right: 1rem !important;
}
.tasks {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}